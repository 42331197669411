export const API_ROUTES = {
  USER: {
    PAGINATE: { method: 'post', url: 'admin/v1/user/paginate' },
  },

  Auth: {
    forgot: { method: 'post', url: 'admin/v1/auth/forgot-password' },
  },
  Master: {
    paginate: { method: 'post', url: 'admin/v1/master/paginate' },
    getDetail: { method: 'get', url: 'admin/v1/master' },
    listByCode: { method: 'post', url: 'admin/v1/master/list-by-code' },
    update: { method: 'put', url: 'admin/v1/master' },
    syncPaginate: { method: 'post', url: '/admin/hk/sync/master', isSync: true },
    create: { method: 'post', url: 'admin/v1/master/create' },
    sequenceUpdate: { method: 'post', url: 'admin/v1/master/bulk-sequence-update' },
    delete: { method: 'post', url: '/admin/v1/account/delete-user-account' },
  },

  Account: {
    create: { method: 'post', url: 'admin/v1/account/create' },
    update: { method: 'post', url: 'admin/v1/account/update' },
    paginate: { method: 'post', url: 'admin/v1/account/paginate' },
    paginateNew: { method: 'post', url: 'admin/v1/account/paginate_new' },
    syncPaginate: { method: 'post', url: 'admin/hk/sync/ledger', isSync: true },
    get: { method: 'post', url: 'admin/v1/account' },
    getDetail: { method: 'post', url: '/admin/v1/account/getdetails' },
    delete: { method: 'post', url: '/admin/v1/account/delete-user-account' },
    AccountCodeUpdate: { method: 'post', url: '/admin/v1/account/update/code' },
    getUsers: { method: 'post', url: 'admin/v1/account/getUsers' },
    changeStatus: { method: 'post', url: 'admin/v1/account/changeStatus' },
    UpsertVendorFolder: {
      method: 'post',
      url: '/diamond-sheet/upsert-vendor-folder',
      isFileUploadUrl: true,
    },
    vendorList: { method: 'post', url: '/diamond-sheet/vendor-folder-list', isFileUploadUrl: true },
    // UpdateVendorFolder: { method: "post", url: "/diamond-sheet/updateVendorFolder", isFileUploadUrl: true },
    AccountList: { method: 'post', url: '/admin/v1/user/sub-user-list' },
    getHighEndClient: { method: 'post', url: '/admin/v1/account/highEndClients' },
  },

  Seller: {
    paginate: { method: 'post', url: 'admin/v1/seller/paginate' },
  },

  Shipping: {
    detail: { method: 'post', url: 'admin/v1/shipping-detail/' },
  },

  Event: {
    paginate: { method: 'post', url: 'admin/v1/event/paginate' },
    create: { method: 'post', url: 'admin/v1/event/create' },
    update: { method: 'put', url: 'admin/v1/event/' },
    find: { method: 'get', url: 'admin/v1/event/' },
    sequenceUpdate: { method: 'post', url: '/admin/v1/event/sequence' },
  },

  Version: {
    paginate: { method: 'post', url: 'admin/v1/version/paginate' },
    create: { method: 'post', url: 'admin/v1/version/create' },
    update: { method: 'post', url: 'admin/v1/version/update' },
    find: { method: 'get', url: 'admin/v1/version/view/' },
    delete: { method: 'delete', url: 'admin/v1/version/destroy/' },
  },

  QuoteDay: {
    paginate: { method: 'post', url: 'admin/v1/quote-day/paginate' },
    create: { method: 'post', url: 'admin/v1/quote-day/create' },
    update: { method: 'post', url: 'admin/v1/quote-day/update' },
    view: { method: 'get', url: 'admin/v1/quote-day/view/' },
    delete: { method: 'post', url: 'admin/v1/quote-day/delete' },
  },

  Contactus: {
    paginate: { method: 'post', url: 'admin/v1/contactus/paginate' },
  },

  Sizemaster: {
    paginate: { method: 'post', url: 'admin/v1/size/paginate' },
    create: { method: 'post', url: 'admin/v1/size/create' },
    view: { method: 'get', url: 'admin/v1/size' },
    update: { method: 'put', url: 'admin/v1/size' },
    syncPaginate: { method: 'post', url: 'admin/hk/sync/size', isSync: true },
  },

  CutGroup: {
    paginate: { method: 'post', url: '/admin/v1/cut-group/paginate' },
    create: { method: 'post', url: '/admin/v1/cut-group/create' },
    view: { method: 'get', url: '/admin/v1/cut-group' },
    update: { method: 'put', url: '/admin/v1/cut-group' },
  },

  Terms: {
    paginate: { method: 'post', url: 'admin/v1/day-term/paginate' },
    list: { method: 'get', url: '/admin/v1/day-term/' },
    syncPaginate: { method: 'post', url: '/admin/hk/sync/accountTerm', isSync: true }, //admin/v1
  },

  Gridcolumn: {
    list: { method: 'post', url: 'admin/v1/grid/get-columns' },
    update: { method: 'post', url: 'admin/v1/grid/upsert' },
    dropdownlist: { method: 'post', url: 'admin/v1/grid/list' },
  },

  Comment: {
    paginate: { method: 'post', url: 'admin/v1/diamond-comment/by-user' },
  },

  Diamond: {
    paginate: { method: 'post', url: 'admin/v1/diamond/paginate' },
    search: { method: 'post', url: 'admin/v1/diamond/search/list' },
    delete: { method: 'post', url: 'admin/v1/diamond/search/delete' },
    quickSearch: { method: 'post', url: 'admin/v1/diamond/quick-search' },
    syncPaginate: { method: 'post', url: '/admin/hk/sync/diamond', isSync: true }, //admin/v1
    specialStonePaginate: { method: 'post', url: 'admin/v1/diamond/specialStonePaginate' },
    excel: { method: 'post', url: '/admin/v1/diamond/excel' }, //admin/v1
    upload: { method: 'post', url: '/admin/v1/diamond/upload-sheet' }, //admin/v1
  },

  Time: {
    getTime: { method: 'get', url: '/admin/v1/common/server-time' },
    getBidTime: { method: 'post', url: '/admin/v1/diamond-bid/bid-config' },
  },

  PlatformDetail: {
    paginate: { method: 'post', url: 'admin/v1/sync-platform-inventory/paginate' },
    add: { method: 'post', url: '/admin/v1/sync-platform-inventory/create' },
    view: { method: 'get', url: '/admin/v1/sync-platform-inventory' },
    update: { method: 'put', url: '/admin/v1/sync-platform-inventory' },
    delete: { method: 'post', url: '/admin/v1/sync-platform-inventory/destroy' },
    sync: { method: 'post', url: '/admin/v1/sync-platform-inventory/sync' },
  },

  TermsDetail: {
    paginate: { method: 'post', url: '/admin/v1/account-term/paginate' },
    add: { method: 'post', url: '/admin/v1/account-term/create' },
    view: { method: 'get', url: '/admin/v1/account-term/' },
    update: { method: 'post', url: '/admin/v1/account-term/update' },
    approve: { method: 'post', url: '/admin/v1/account-term/approve' },
    delete: { method: 'post', url: '/admin/v1/account-term/delete' },
  },

  Banner: {
    paginate: { method: 'post', url: '/admin/v1/banner/paginate' },
    add: { method: 'post', url: '/admin/v1/banner/create' },
    view: { method: 'get', url: '/admin/v1/bannerview/' },
    update: { method: 'put', url: '/admin/v1/banner/' },
    approve: { method: 'post', url: '/admin/v1/banner/approve' },
    delete: { method: 'delete', url: '/admin/v1/banner/' },
  },

  News: {
    paginate: { method: 'post', url: 'admin/v1/news/paginate' },
    add: { method: 'post', url: '/admin/v1/news/create' },
    update: { method: 'put', url: '/admin/v1/news/' },
    delete: { method: 'delete', url: '/admin/v1/news/' },
    edit: { method: 'get', url: '/admin/v1/news/' },
  },

  FeatureStone: {
    paginate: { method: 'post', url: '/admin/v1/featuredStone/paginate' },
    add: { method: 'post', url: '/admin/v1/featuredStone/create' },
    delete: { method: 'post', url: '/admin/v1/featuredStone/destroy' },
  },

  Country: {
    paginate: { method: 'post', url: 'admin/v1/country/paginate' },
    add: { method: 'post', url: '/admin/v1/country/add' },
    update: { method: 'post', url: '/admin/v1/country/update' },
    delete: { method: 'post', url: '/admin/v1/country/delete' },
    fetchId: { method: 'post', url: '/admin/v1/country/' },
    syncPaginate: { method: 'post', url: 'admin/hk/sync/country', isSync: true }, //admin/v1
  },

  State: {
    paginate: { method: 'post', url: 'admin/v1/state/paginate' },
    add: { method: 'post', url: '/admin/v1/state/add' },
    update: { method: 'post', url: '/admin/v1/state/update' },
    delete: { method: 'post', url: '/admin/v1/state/delete' },
    syncPaginate: { method: 'post', url: 'admin/hk/sync/state', isSync: true }, //admin/v1
  },

  City: {
    paginate: { method: 'post', url: 'admin/v1/city/paginate' },
    add: { method: 'post', url: '/admin/v1/city/add' },
    update: { method: 'post', url: '/admin/v1/city/update' },
    delete: { method: 'post', url: '/admin/v1/city/delete' },
    syncPaginate: { method: 'post', url: 'admin/hk/sync/city', isSync: true }, //admin/v1
  },

  Parcel_master: {
    paginate: { method: 'post', url: 'admin/v1/parcel-price/paginate' },
    dropdownlist: { method: 'post', url: 'admin/v1/parcel-price/get-dates' },
    update: { method: 'post', url: 'admin/v1/parcel-price/upsert' },
  },

  Pricing: {
    paginate: { method: 'post', url: '/admin/v1/pricing/paginate' },
    sendEmail: { method: 'post', url: '/admin/v1/diamond/revise-price/notification' },
  },

  Feedback: {
    paginate: { method: 'post', url: 'admin/v1/feedback/paginate' },
    destroy: { method: 'post', url: 'admin/v1/feedback/destroy' },
  },

  FeaturedStone: {
    paginate: { method: 'post', url: 'admin/v1/featuredStone/paginate' },
    destroy: { method: 'post', url: 'admin/v1/featuredStone/destroy' },
    create: { method: 'post', url: 'admin/v1/featuredStone/create' },
  },

  TrackType: {
    paginate: { method: 'post', url: 'admin/v1/diamond-track/paginate' },
  },

  ActiveDeactive: {
    list: { method: 'post', url: 'admin/v1/common/boolean-status-update' },
  },

  DiamondTrack: {
    paginate: { method: 'post', url: '/admin/v1/diamond-track/paginate' },
    changeStatus: { method: 'post', url: '/admin/v1/diamond-track/change-status' },
    create: { method: 'post', url: 'admin/v1/diamond-track/create' },
    delete: { method: 'post', url: 'admin/v1/diamond-track/delete' },
    statusUpdate: { method: 'post', url: '/admin/v1/diamond-track/status-update' },
  },

  DiamondBid: {
    paginate: { method: 'post', url: '/admin/v1/diamond-bid/paginate' },
    changeStatus: { method: 'post', url: '/admin/v1/diamond-bid/change-status' },
    create: { method: 'post', url: '/admin/v1/diamond-bid/create' },
    dynamicBid: { method: 'post', url: '/admin/v1/dynamic-diamond-bid/paginate' },
    winner: { method: 'post', url: '/admin/v1/dynamic-diamond-bid/bids-result' },
    downloadExcel: { method: 'post', url: '/admin/v1/dynamic-diamond-bid/export' },
    bidDelete: { method: 'post', url: `/admin/v1/dynamic-diamond-bid/delete` },
    sendEmail: { method: 'post', url: `/admin/v1/dynamic-diamond-bid/email-final` },
  },

  VendorDiamond: {
    updateStatus: { method: 'post', url: '/admin/v1/vendor-diamond/update-status' },
  },

  User: {
    paginate: { method: 'post', url: 'admin/v1/user/paginate' },
    syncPaginate: { method: 'post', url: 'admin/hk/sync/user', isSync: true }, //admin/v1
    ResetPassword: { method: 'post', url: 'admin/v1/user/reset-password' },
    Upsert: { method: 'post', url: 'admin/v1/user/create' },
    update: { method: 'put', url: 'admin/v1/user/' },
    VerifiedUser: { method: 'post', url: 'admin/v1/user/verify-users' },
    delete: { method: 'post', url: '/admin/v1/user/admin-user-delete' },
    getDetail: { method: 'get', url: '/admin/v1/user/' },
    verifiedEmail: { method: 'post', url: '/admin/v1/user/resend-verification-email' },
    createMany: { method: 'post', url: '/admin/v1/user/create-many' },
    generateToken: { method: 'post', url: '/admin/v1/auth/user-token-generate' },
    downloadExcel: { method: 'post', url: '/admin/v1/user/generate-user-excel' },
    resenVerificationEmail: { method: 'post', url: '/admin/v1/user/resendVerificationEmail' },
  },

  Permission: {
    update: { method: 'post', url: 'admin/v1/permission/upsert' },
    paginate: { method: 'post', url: 'admin/v1/permission/paginate' },
    listByRole: { method: 'get', url: 'admin/v1/permission/view-by-role/' },
    listByCode: { method: 'post', url: 'admin/v1/master/list-by-code' },
    listByUser: { method: 'post', url: 'admin/v1/permission/user-permission' },
  },

  Setting: {
    getSetting: { method: 'get', url: 'admin/v1/setting/' },
    upsert: { method: 'post', url: '/admin/v1/setting/upsert' },
    view: { method: 'post', url: '/admin/v1/setting/view' },
    loginSetting: { method: 'post', url: 'admin/v1/account/SetLoginRestrictions' },
    promotionalDisSetting: { method: 'post', url: '/admin/v1/project-setting/view' },
    promotionalDisUpdate: { method: 'post', url: '/admin/v1/project-setting/upsert' },
  },

  Notification: {
    BulkEmail: { method: 'post', url: '/admin/v1/bulknotification/process' },
    paginate: { method: 'post', url: '/admin/v1/notification/paginate' },
    list: { method: 'post', url: '/admin/v1/notification/list' },
    upsert: { method: 'post', url: '/admin/v1/notification/sendNotification' },
    read: { method: 'post', url: '/admin/v1/notification/markAsRead' },
  },

  PageTracking: {
    paginate: { method: 'post', url: '/admin/v1/analytics/paginate' },
  },

  Transport: {
    paginate: { method: 'post', url: '/admin/v1/transport/paginate' },
    update: { method: 'post', url: '/admin/v1/transport/update' },
  },

  Hospitality: {
    paginate: { method: 'post', url: '/admin/v1/product-order/paginate' },
    update: { method: 'post', url: '/admin/v1/product-order/update' },
  },

  Career: {
    paginate: { method: 'post', url: '/admin/v1/career/master/paginate' },
    create: { method: 'post', url: '/admin/v1/career/master/create' },
    delete: { method: 'post', url: '/admin/v1/career/master/delete' },
    update: { method: 'post', url: '/admin/v1/career/master/update' },
  },

  Product: {
    paginate: { method: 'post', url: '/admin/v1/master/list-by-code' },
    mainPaginate: { method: 'post', url: '/admin/v1/product/paginate' },
    sequenceUpdate: { method: 'post', url: '/admin/v1/product/bulk-sequence-update' },
  },

  Platform: {
    paginate: { method: 'post', url: '/admin/v1/user/by-login-device-type' },
  },

  ResetPassword: {
    paginate: { method: 'post', url: '/auth/reset-password-by-user' }, //admin/v1
  },

  SearchHistory: {
    paginate: { method: 'post', url: '/admin/v1/diamond/search/history' },
  },

  Location: {
    paginate: { method: 'post', url: '/admin/v1/news-event/paginate' },
    getsingle: { method: 'get', url: '/admin/v1/news-event/' },
    upsert: { method: 'put', url: '/admin/v1/news-event/' },
  },

  UploadHistory: {
    paginate: { method: 'post', url: '/admin/v1/diamond-sheet/history', isFileUploadUrl: true },
    ErrorRecord: { method: 'post', url: '/admin/v1/diamond-sheet/error-records' },
  },

  StockSummary: {
    paginate: { method: 'post', url: '/admin/v1/diamond/summary-report', isSync: false },
    inventory: { method: 'post', url: '/admin/v1/inventory/summary', isSync: false },
    list: { method: 'post', url: '/admin/v1/diamond/summary', isSync: false },
  },

  StockDist: {
    summary: { method: 'post', url: 'admin/v1/bifurcateglobalinventory/get-summary' },
    list: { method: 'post', url: 'admin/v1/bifurcateglobalinventory/getData' },
  },

  dashboard: {
    Admin: { method: 'post', url: '/admin/v1/dashboard' },
    SalesReportAnalysis: { method: 'post', url: '/admin/v1/report/analytics' },
    BluenileandjamesallenSummary: { method: 'post', url: 'admin/v1/analytics/bluenile-vs-james-allen' },
    NewArrivalSummary: { method: 'post', url: 'admin/v1/report/new-arrival-sold-list' },
    DailyVisitReport: { method: 'post', url: 'admin/v1/report/daily-visitors' },
    UserActivityReport: { method: 'post', url: 'admin/v1/report/login-activity' },
  },

  MatchPair: {
    Paginate: { method: 'post', url: 'admin/v1/match-pair/diamond/filter' },
    Insert: { method: 'post', url: 'admin/v1/match-pair/diamond/filter' },
  },

  Demand: {
    Upsert: { method: 'post', url: '/admin/v1/diamond/search/upsert' },
    PartialUpsert: { method: 'post', url: '/admin/v1/diamond/search/partial-upsert' },
  },

  Theme: {
    project: { method: 'post', url: 'admin/v1/theme-setup/paginate' },
    upsert: { method: 'post', url: 'admin/v1/theme-setup/upsert' },
  },

  File: {
    Upload: { method: 'post', url: '/admin/v1/upload-file' },
  },

  Inventory: {
    exportExcel: { method: 'post', url: '/admin/v1/diamond/excel' },
    printPdf: { method: 'post', url: '/admin/v1/diamond/pdf' },
    xRayMail: { method: 'post', url: '/admin/v1/diamond/x-ray-mail' },
    diamondComment: { method: 'post', url: '/admin/v1/diamond-comment/upsert' },
    diamondCommentPaginate: { method: 'post', url: '/admin/v1/diamond-comment/paginate' },
    downloadZip: { method: 'post', url: '/admin/v1/diamond/download-zip' },
    downloadFile: { method: 'post', url: '/admin/v1/common/download-file' },
    ftc: { method: 'post', url: '/admin/v1/diamond/diamond-ftc' },
    downloadCertificate: { method: 'post', url: '/admin/v1/diamond/download-certificate' },
    imageCheck: { method: 'post', url: '/admin/v1/diamond/diamond-files' },
    stockFileUpload: { method: 'post', url: '/admin/v1/diamond/upload-processed-sheet' },
    getStockDate: { method: 'get', url: '/admin/v1/diamond/get-date' },
    setStockDate: { method: 'post', url: '/admin/v1/diamond/save-date' },
  },

  DiamondConfirm: {
    Request: { method: 'post', url: 'admin/v1/diamond-confirm/request' },
  },

  Memo: {
    Insert: { method: 'post', url: '/admin/v1/diamond-block/create' },
    paginate: { method: 'post', url: 'admin/v1/diamond-block/paginate' },
    changeStatus: { method: 'post', url: '/admin/v1/diamond-block/change-status' },
  },

  Broker: {
    list: { method: 'post', url: '/admin/v1/broker/list' },
  },

  UserTerms: {
    Request: { method: 'post', url: '/admin/v1/user/user-term' },
  },

  DiamondBlockMaster: {
    paginate: { method: 'post', url: '/admin/v1/diamond-block/master/paginate' },
  },

  Order: {
    paginate: { method: 'post', url: '/admin/v1/memo/paginate' },
    statusChange: { method: 'post', url: 'admin/v1/order/change-status' },
  },

  projectSettings: {
    view: { method: 'post', url: '/admin/v1/project-setting/view' },
  },

  office: {
    list: { method: 'post', url: '/admin/v1/cabin-slot/paginate' },
    create: { method: 'post', url: '/admin/v1/cabin-schedule/create' },
    paginate: { method: 'post', url: 'admin/v1/cabin-schedule/list' },
    destroy: { method: 'post', url: 'admin/v1/cabin-schedule/destroy' },
    statusUpdate: { method: 'post', url: 'admin/v1/cabin-schedule/update-status' },
    config: { method: 'get', url: 'admin/v2/configOffer' },
  },

  officeView: {
    list: { method: 'post', url: '/admin/v1/cabin-schedule/list' },
  },

  ProformaReport: {
    paginate: { method: 'post', url: '/admin/v1/proforma/paginate' },
    instruction: { method: 'post', url: '/admin/v1/proforma/instruction' },
  },

  Report: {
    seReport: { method: 'post', url: '/admin/v1/report/se-report' },
    showSelection: { method: 'post', url: '/admin/v1/show-selection/diamond-wise-show-selection' },
  },

  showSelection: {
    create: { method: 'post', url: 'admin/v1/show-selection/create' },
    pagination: { method: 'post', url: 'admin/v1/show-selection/paginate' },
    delete: { method: 'post', url: '/admin/v1/show-selection/delete' },
  },

  diamondComment: {
    list: { method: 'post', url: '/admin/v1/diamond-comment/paginate' },
  },

  todo: {
    paginate: { method: 'post', url: '/admin/v1/todo/paginate' },
    create: { method: 'post', url: '/admin/v1/todo/create' },
    update: { method: 'post', url: '/admin/v1/todo/update' },
    view: { method: 'post', url: '/admin/v1/todo/view' },
  },

  chequeDetails: {
    paginate: { method: 'post', url: '/admin/v1/cheque/paginate' },
    update: { method: 'post', url: '/admin/v1/cheque/update' },
  },

  VIEW_DIAMOND: { method: 'post', url: '/web/v1/diamond/view' },

  UserPermit: {
    Paginate: { method: 'post', url: '/admin/v1/permission/user-permission-list' },
    Update: { method: 'post', url: '/admin/v1/permission/user-permission-update' },
  },

  Dashboard: {
    Search: { method: 'post', url: '/admin/v1/dashboard/search' },
    Inventory: { method: 'post', url: '/admin/v1/dashboard/inventory' },
    Enquiry: { method: 'post', url: '/admin/v1/dashboard/enquiry' },
    Details: { method: 'post', url: '/admin/v1/dashboard/details' },
  },

  Certificate: {
    create: { method: 'post', url: '/admin/v1/diamond/uploaddocs' },
  },

  CheckPacket: {
    Paginate: { method: 'post', url: '/admin/v1/ex/thirdparty/getData ' },
    ExportExcel: { method: 'post', url: 'admin/v1/ex/brainTree/in-hand-memo/export-excel' },
  },

  bidConfig: {
    paginate: { method: 'post', url: '/admin/v1/bid-configuration/paginate' },
    create: { method: 'post', url: '/admin/v1/bid-configuration/create' },
    update: { method: 'put', url: '/admin/v1/bid-configuration/update' },
    delete: { method: 'post', url: '/admin/v1/bid-configuration/destroy' },
    view: { method: 'get', url: '/admin/v1/bid-configuration/' },
    bidUpsert: { method: 'post', url: '/admin/v1/bid-configuration/bidUpsertUpdate' },
    searchListBid: { method: 'post', url: '/admin/v1/diamond/search/searchListBid' },
    bidPaginateDiamonds: { method: 'post', url: '/admin/v1/bid-configuration/bidPaginateDiamonds' },
    syncWithIndividual: { method: 'post', url: '/admin/v1/bid-configuration/sync' },
  },

  password: {
    change: { method: 'post', url: '/admin/v1/auth/reset-password-by-user' },
  },

  MatchPairSetting: {
    upsert: { method: 'post', url: '/admin/v1/pair/upsert' },
    pagination: { method: 'post', url: '/admin/v1/pair/paginate' },
    delete: { method: 'post', url: '/admin/v1/pair/delete' },
  },

  Offer: {
    UploadExcel: { method: 'post', url: '/admin/v1/diamond-track/create-by-excel' },
    Counter: { method: 'post', url: '/admin/v1/diamond-track/counter-offer' },
  },

  Target: {
    create: { method: 'post', url: '/admin/v2/target/create' },
    paginate: { method: 'get', url: '/admin/v2/target/paginate' },
    sellerData: { method: 'get', url: '/admin/v2/target/fetchSeller' },
    targetData: { method: 'get', url: '/admin/v2/target/fetchTarget' },
    delete: { method: 'delete', url: '/admin/v2/target/delete' },
    update: { method: 'put', url: '/admin/v2/target/update' },
  },
};

export default API_ROUTES;
